import React, {createContext, useState, useEffect} from "react";
import JWT from "jwt-decode";
import Api from "utils/Api";

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [Authenticated, setAuthenticated] = useState(false);
  const [Token, setToken] = useState("");
  const [Access, setAccess] = useState("");
  const [Usuario, setUsuario] = useState("");
  const [Loja, setLoja] = useState("");
  const [ID, setID] = useState("");
  const [Cliente, setCliente] = useState("");
  const [Inti, setInti] = useState(false);
  const [UseMatrizDefeito, setUseMatrizDefeito] = useState(false);

  const setInfo = (token) => {
    const decoded = JWT(token);
    setUseMatrizDefeito(decoded.UseMatrizDefeito == "True");
    setToken(token);
    setID(decoded.UserID);
    setAccess(decoded.UserAccess);
    localStorage.setItem("id", decoded.UserAccess);
    setUsuario(decoded.name);
    setLoja(decoded.UserLoja);
    setCliente(decoded.UserDataCliente);
    localStorage.setItem("cliente", decoded.UserDataCliente);
    setAuthenticated(true);
  };

  const setAuth = (data) => {
    try {
      setToken(data);
      localStorage.setItem("token", JSON.stringify(data));
      setInfo(data);
    } catch (error) {
      console.error(error);
    }
  };

  const Logoff = () => {
    try {
      if (Authenticated) {
        setToken("");
        localStorage.setItem("token", "");
        setID("");
        setAccess("");
        localStorage.setItem("id", null);
        setUsuario("");
        setLoja("");
        setCliente("");
        localStorage.setItem("cliente", null);
        setAuthenticated(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Logoff ~ error:", error);
    }
  };

  const GatAuthorization = (addDataHeaders) => {
    if (Token !== "") {
      if (addDataHeaders !== undefined) {
        return {
          headers: {
            Authorization: `Bearer ${Token}`,
            ...addDataHeaders,
          },
        };
      } else {
        return {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        };
      }
    } else {
      Logoff();
    }
  };

  const Request = new Api(Logoff);

  useEffect(() => {
    try {
      const localToken = localStorage.getItem("token");
      if (localToken.length !== 0) {
        setInfo(JSON.parse(localToken));
      } else {
        Logoff();
      }
      setInti(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        UseMatrizDefeito,
        Request,
        GatAuthorization,
        Inti,
        Access,
        setAuth,
        Logoff,
        Authenticated,
        Token,
        ID,
        Usuario,
        Loja,
        Cliente,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
