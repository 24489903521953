import axios from 'axios';

class Api {
  constructor(Logoff) {
    this.logout = Logoff;
    this.baseURL = process.env.REACT_APP_API_URL
  }

  /**
   * Método Request GET
   * @param {*} param0 
   * @returns {json} response
   */
  async Get({ url, endpoint, params, config }) {
    try {
      if (url !== undefined) {
        if (params !== undefined) {
          let query = '';
          const nameParams = Object.getOwnPropertyNames(params);

          nameParams.forEach((item, i) => {
            if (i === 0) {
              query = `?${item}=${params[item]}`;
            } else {
              query += `&${item}=${params[item]}`;
            }
          });

          const response = await axios.get(`${url}${query}`, config);
          return response.data;
        }
        const response = await axios.get(url, config);
        return response.data;
      }
      if (params !== undefined) {
        let query = '';
        const nameParams = Object.getOwnPropertyNames(params);

        nameParams.forEach((item, i) => {
          if (i === 0) {
            query = `?${item}=${params[item]}`;
          } else {
            query += `&${item}=${params[item]}`;
          }
        });

        const response = await axios.get(`${this.baseURL}${endpoint}${query}`, config);
        return response.data;
      }

      const response = await axios.get(`${this.baseURL}${endpoint}`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.logout();
        return null;
      }
      throw error;
    }
  }

  /**
   * Método Request POST
   * @param {*} param0 
   * @returns {json} response
   */
  async Post({ endpoint, data, config }) {
    try {
      const response = await axios.post(`${this.baseURL}${endpoint}`, data, config);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        this.logout()
        return null;
      } else {
        throw error;
      }
    }
  }

  /**
   * Método Request PUT
   * @param {*} param0 
   * @returns {json} response
   */
  async Put({ endpoint, data, config }) {
    try {
      const response = await axios.put(`${this.baseURL}${endpoint}`, data, config);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        this.logout()
        return null;
      } else {
        throw error;
      }
    }
  }

  /**
   * Método Request DELETE
   * @param {*} param0 
   * @returns {json} response
   */
  async Delete({ endpoint, config }) {
    try {
      const response = await axios.delete(`${this.baseURL}${endpoint}`, config);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        this.logout()
        return null;
      } else {
        throw error;
      }
    }
  }
}

export default Api;